

































import { notifyError } from "@/components/Notification";
import Vue from "vue";
import { mapActions } from "vuex";

const stateBase = "select";

export default Vue.extend({
  name: "btn-procedure-status",
  props: {
    procedure: Object,
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      state: "",
      loading: false,
      states: [
        {
          label: "Select state",
          value: stateBase,
        },
        {
          label: "New",
          value: "NEW",
        },
        {
          label: "Evaluation",
          value: "EVALUATION",
        },
        {
          label: "Not interested",
          value: "NO_INTERESTED",
        },
        {
          label: "Pending",
          value: "PENDING",
        },
        {
          label: "Payment in Process",
          value: "PAYMENT_IN_PROCCESS",
        },
        {
          label: "Finished payment",
          value: "FINISHED_PAYMENT",
        },
        // {
        //   label: "Done",
        //   value: "DONE",
        // },
      ],
    };
  },
  watch: {
    procedure: {
      deep: true,
      immediate: true,
      handler(procedure: any) {
        this.state = procedure.dasboardStatus;
      },
    },
  },
  computed: {
    stateLabel(): string {
      const result = this.states.find((state) => state.value == this.state);

      return result?.label as string;
    },
  },
  mounted() {
    this.setState(stateBase);
    this.state = this.procedure.dasboardStatus;
  },
  methods: {
    ...mapActions("crmDashboardModule", ["actUpdateProcedureStatus"]),
    setState(state: string): void {
      this.state = state;
      if (state == stateBase) return;

      this.loading = true;
      this.actUpdateProcedureStatus({
        id: this.procedure.id,
        status: state,
      })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.loading = false;
        });

      if (this.state != stateBase) {
        this.$emit("state", state);
      }
    },
  },
});
